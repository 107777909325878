import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link, navigate } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";

import styles from "./personalization-cards.module.scss";

/*
 * 3 Small cards image widths at all breakpoints:
 * layout:     | ----------------- 1 col ----------------- | --- 2 col ---|------------------- 3 col -------------------- |
 * Container:  | --- xxs --- | ---- xs ---- | ---- sm ---- | ---- md ---- | ---- lg ----- | ---- xl ----- | ---- xxl ----->
 * Breakpoint: 0            375            600            768            992             1200            1920             ~
 * Img width:  |   290-344   |   345-370    |      546     |     330      |      290      |      350      |      350      |
 *
 * NOTE: passed imaVariable should use "width: 550"
 */

const StretchedLinkCards = (props) => {
  // const growClass = props.hasGrowEffect ? "grow" : "";

  const desktopClass = props.useMobileView === true ? "d-none d-md-flex" : "";

  const isLastCard = (idx) => {
    return idx === props.cards.length - 1;
  };

  const onCardEnter = ({ key }, { url }) => {
    if (key === "Enter" && url) {
      navigate(url);
    }
  };

  const makeCardLinkId = (id, isInBlog) => {
    if (!id) return "";
    if (!isInBlog) return `${id}-url-link`;
    return `${id}-link`;
  };

  // Please use this component when you have only one URL and want to be a stretched link to make an entire card clickable.
  // Otherwise use "no-link-cards.js"

  const DEFAULT_IMAGE = {
    altText: "WaFd Bank logo.",
    imgSrc: "/images/default-source/card-images/thumbnail-walt-camera-081624.jpg"
  };

  /*
   * NOTE: please do NOT change the margin-bottom classes on these cards, it should be "mb-3 mb-lg-4".
   * Do NOT override the spacing using padding or margin classes on the props.sectionClass.
   * If you need to override the section padding because the cards are connected
   * to other sections that need to look like one section, pass the sectionClass
   * "section-padding-top", "section-padding-none", "section-padding-bottom"
   */
  return (
    <div id={props.sectionId} className={`section-padding-adjusted ${props.sectionClass}`}>
      <div className={`container ${props.containerClass}`}>
        {props.title && <h2 className={props.titleClass}>{props.title}</h2>}
        <ul className={`row mb-0 pl-0 list-unstyled ${desktopClass} ${props.rowColsClass}`}>
          {props.cards.map((card, idx) => (
            <li key={idx} className={`col mb-3 mb-lg-4 ${card.cardClass}`}>
              <div
                id={card.id}
                tabIndex={0}
                onKeyDown={(e) => onCardEnter(e, card)}
                className={`card h-100 position-relative overflow-hidden border-radius-12 border-0 ${styles.stretchedLinkCard}`}
              >
                {!card?.image?.imgSrc && !card?.image?.imgVariable && (
                  <img
                    className={`card-image-top border-radius-12 border-radius-bottom-0`}
                    src={DEFAULT_IMAGE.imgSrc}
                    alt={DEFAULT_IMAGE.altText}
                  />
                )}
                {card?.image?.imgSrc && (
                  <img
                    className={`card-image-top border-radius-12 border-radius-bottom-0`}
                    src={card.image.imgSrc}
                    alt={card.image.altText}
                  />
                )}
                {card?.image?.imgVariable && (
                  <GatsbyImage
                    className={`card-image-top border-radius-12 border-radius-bottom-0`}
                    image={card.image.imgVariable}
                    alt={card.image.altText || ""}
                  />
                )}
                <div className="card-body d-flex flex-column align-items-start">
                  {/* Make the title text green if a card has description */}
                  {/* Add the arrow icon and hover effects to the title when a card doesn't have a description */}
                  {card.title && (
                    <h3
                      className={`card-title ${card.titleClass} ${
                        card.text ? "text-success" : `${styles.cardDescription}`
                      }`}
                    >
                      <span dangerouslySetInnerHTML={{ __html: card.title }} />
                      {!card.text && <Icon name="arrow-right" class="ml-1 text-primary" />}
                    </h3>
                  )}
                  {card.text && (
                    <p className={`card-text ${styles.cardDescription} ${card.textClass}`}>
                      <span dangerouslySetInnerHTML={{ __html: card.text }} />
                      {card.arrowText && <span className="text-primary text-uppercase ml-1">{card.arrowText}</span>}
                      {card.showTextArrow && <Icon name="arrow-right" class="ml-1 text-primary" />}
                    </p>
                  )}
                  {card.tertiaryText && <p className="mb-0 text-sm">{card.tertiaryText}</p>}
                  {card.url && (
                    <Link
                      id={makeCardLinkId(card.id, card.isInBlog)}
                      aria-hidden="true"
                      tabIndex="-1"
                      to={card.url}
                      className="stretched-link"
                    />
                  )}
                  {card.externalUrl && (
                    <a
                      id={makeCardLinkId(card.id, card.isInBlog)}
                      aria-hidden="true"
                      tabIndex="-1"
                      href={card.externalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="stretched-link"
                    >
                      <span className="sr-only">{card.title}</span>
                    </a>
                  )}
                  {card.anchorUrl && (
                    <AnchorLink to={card.anchorUrl} className="stretched-link">
                      <span className="sr-only" dangerouslySetInnerHTML={{ __html: card.text }} />
                    </AnchorLink>
                  )}
                  {card.button && card.button.text && <Button {...card.button} />}
                </div>
              </div>
            </li>
          ))}
        </ul>

        {props.useMobileView && (
          <div className="row d-flex d-md-none">
            {props.cards.map((card, idx) => {
              return (
                <div
                  className={`col-12 pb-2 mb-3 position-relative grow${!isLastCard(idx) ? " border-bottom" : ""}`}
                  id={card.id}
                  key={idx}
                >
                  <div className="row align-items-center">
                    <div className="col-3">
                      {card?.image?.imgVariable && (
                        <GatsbyImage
                          image={card.image.imgVariable}
                          alt={card.image.altText}
                          className="p-5 border-radius-12 border-radius-bottom-0"
                        />
                      )}
                    </div>
                    <div className="col-9 pl-5 pl-sm-3">
                      <p className={`card-text mb-0 ${styles.cardDescription} ${card.textClass}`}>{card.text}</p>
                      <Icon name="arrow-right" class="ml-1 text-primary" />
                    </div>
                  </div>
                  <Link to={card.url} className="stretched-link">
                    <span className="sr-only">{card.title}</span>
                  </Link>
                  {card.anchorUrl && (
                    <AnchorLink to={card.anchorUrl} className="stretched-link">
                      <span className="sr-only">{card.title}</span>
                    </AnchorLink>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {props.button && props.button.text && <Button {...props.button} />}
        {props.link && props.linkText && (
          <div className={`pb-3 ${props.textAlignClass}`}>
            <Link id={props.linkId} to={props.link} className={props.linkClass}>
              {props.linkText}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
export default StretchedLinkCards;

StretchedLinkCards.defaultProps = {
  sectionClass: "bg-light",
  sectionId: "link-cards-section",
  containerClass: "",
  rowColsClass: "row-cols-1 row-cols-md-2 row-cols-lg-3",
  titleClass: "",
  title: "",
  hasGrowEffect: false,
  hasRiseShadowEffect: false,
  button: {},
  textAlignClass: "text-sm-center",
  linkClass: "font-weight-bold",
  link: "",
  linkText: "",
  linkId: "",
  useMobileView: false,
  cards: [
    {
      id: "link-card-1",
      cardClass: "",
      url: "",
      anchorUrl: "",
      image: {
        imgSource: null,
        imgVariable: null,
        altText: ""
      },
      titleClass: "",
      title: "",
      textClass: "",
      text: "",
      showTextArrow: true,
      button: {},
      isInBlog: false
    }
  ]
};

