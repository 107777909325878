import React from "react";
import { Link } from "gatsby";

// Styles
import "./breadcrumb.bootstrap.scss";

const Breadcrumb = ({ location, specialCasing = {} }) => {
  //console.log("(Breadcrumb.js) location: ", location);
  let locationPath = location && location.pathname ? location.pathname : "/"; // (typeof window !== 'undefined' ? window.location.href :  '/');
  locationPath = decodeURI(locationPath);
  // console.log("locationPath: ", locationPath);

  let pageUrlParts = locationPath.split("/");
  //console.log("page url parts: ", pageUrlParts);
  let activeLength = pageUrlParts.length - 1;

  let crumbs = [];
  pageUrlParts.map((part, index) => {
    if (index < 1) {
      let crumb = {
        id: index,
        title: "WaFd Bank",
        url: "/",
        active: index === activeLength ? "active" : ""
      };
      crumbs.push(crumb);
    } else {
      // replace dashes with spaces and then uppercase the first letter of each word
      let pageName =
        specialCasing[part] ||
        part
          .toLowerCase()
          .replace(/[-_.]/g, " ")
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
      let path = "";
      for (var i = 1; i <= index; i++) {
        path = path + "/" + pageUrlParts[i];
      }
      let curmb = {
        id: index,
        title: pageName.includes("Voice Activated Banking")
          ? pageName.replace("Voice Activated Banking", "Voice-Activated Banking")
          : pageName,
        url: path,
        active: index === activeLength ? "active" : ""
      };
      crumbs.push(curmb);
    }
    return <></>;
  });
  //console.log("Crumbs: ", crumbs);
  const lng = crumbs.length;

  if (lng > 2) {
    for (let a = 0; a < lng - 2; a++) {
      crumbs[a].class = "d-none d-md-inline-flex";
    }
  }

  return (
    <div className="container">
      <nav aria-label="breadcrumb" className="breadcrumb-nav">
        <ol className="breadcrumb">
          {crumbs.map((crumb) => (
            <li key={crumb.id} className={`breadcrumb-item ${crumb.active} ${crumb.class}`}>
              <Link className="text-black breadcrumb-item-link" to={crumb.url}>
                {crumb.title}
              </Link>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
export default Breadcrumb;

